export const requestData = (window) => {
  let queryString = window.location.search;
  let queryHref = window.location.href;
  let badUrlHitting = false;
  if (queryString == "" || ! ( queryString.includes("vid") || queryString.includes("supplierCode") )) {
    badUrlHitting = true;
  }

  if(queryString != ""){
    queryString = queryString.replace("?","");
  }

  return {
    landingURL : queryHref,
    urlQueryString : queryString,
    badUrlHitting
  }
}