import loadingImg from "../../../assests/loading-wait.jpg";
import mobileImg from "../../../assests/WaitLoading_mobile.svg"
const MyLoader = () => {
    return (
        <>
            <div id="terminateDiv" style={{ position: "relative", width: "100%", height: "100vh" }}>
                <img src={loadingImg} alt="complete" style={{ width: "100%", height: "100%" }} />
                <div style={{ position: "absolute", top: "52%", left: "47%", transform: "translate(-50%, -50%)", zIndex: "10" }}>
                    <img src={process.env.PUBLIC_URL + '/spinner.png'} alt="loader" style={{ width: "150%", animation: "spin 2s linear infinite" }} />
                </div>
            </div>

            <div id="rejectDiv" style={{ position: "relative", width: "100%", height: "100vh" }}>
                <img src={mobileImg} alt="terminate" style={{ width: "100%", height: "100%" }} />
                <div style={{ position: "absolute", top: "43%", left: "47%", transform: "translate(-50%, -50%)", zIndex: "10" }}>
                    <img src={process.env.PUBLIC_URL + '/spinner.png'} alt="loader" style={{ width: "150%", animation: "spin 2s linear infinite" }} />
                </div>
            </div>
        </>
    )
}

export default MyLoader;